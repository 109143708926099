<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs4
                sm4
                md4
              >
                <v-text-field
                  v-model="unitCost"
                  label="Price per Course"
                  hint="amount to deposite"
                  disabled
                  required
                />
              </v-flex>
              <v-flex
                xs1
                sm1
                md1
              >
                <v-text-field
                  value="x"
                  disabled
                />
              </v-flex>
              <v-flex
                xs2
                sm2
                md2
              >
                <v-select
                  v-model="unit"
                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                  label="Unit"
                />
              </v-flex>
              <v-flex
                xs1
                sm1
                md1
              >
                <v-text-field
                  value="="
                  disabled
                />
              </v-flex>
              <v-flex
                xs4
                sm4
                md4
              >
                <v-text-field
                  v-model="amount"
                  label="Total"
                  hint="amount to deposite"
                  disabled
                  required
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="effectDate"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="effectDate"
                    label="Effective Date"
                    hint="Date for this found to start taking effect"
                    prepend-icon="event"
                    required
                  />
                  <v-date-picker
                    v-model="effectDate"
                    @input="$refs.menu1.save(effectDate)"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="expireDate"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="expireDate"
                    label="Expire Date"
                    hint="Date for this found to be expired"
                    prepend-icon="event"
                    required
                  />
                  <v-date-picker
                    v-model="expireDate"
                    @input="$refs.menu2.save(expireDate)"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-textarea
                  v-model="note"
                  label="note down for special occasion"
                  :rules="noteRules"
                  :counter="200"
                  box
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click.native="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click.native="submit()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'StudentFeeDepositeDailog',
  props: ['fee'],
  data() {
    return {
      dialog: false,
      valid: true,
      menu1: false,
      menu2: false,
      studID: '',
      feeID: '',
      type: '',
      action: '',
      effectDate: '',
      expireDate: '',
      unit: 10,
      amountRules: [
        v => !!v || 'Amount is reqired',
        v => (/^\d*$/.test(v)) || 'Only numbers are accepted',
        v => (v && v.length <= 10) || 'Amount must be less than 10 characters',
      ],
      note: '',
      noteRules: [
        // v => (v.length <= 200) || 'Note must be less than 200 characters'
      ],
    }
  },
  computed: {
    title() {
      let actionStr = ''
      if (this.action === 'D') {
        actionStr = 'Deposit to'
      }
      if (this.action === 'W') {
        actionStr = 'Withdraw from'
      }

      return `${actionStr} ${this.type}`
    },
    unitCost() {
      return this.fee.amount
    },
    amount() {
      return this.unitCost * this.unit
    },
  },
  methods: {
    show(studID, feeID, type, action) {
      this.studID = studID
      this.feeID = feeID
      this.type = type
      this.action = action
      this.dialog = true
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('transactions/create', {
          studID: this.studID,
          feeID: this.feeID,
          action: this.action,
          amount: this.amount,
          effectDate: this.effectDate,
          expireDate: this.expireDate,
        })
          .then((data) => {
            this.clear()
            this.dialog = false
            this.$emit('addNewTransaction', data)
          })
      }
    },
    clear() {
      this.effectDate = ''
      this.expireDate = ''
      this.note = ''
    },
  },
}
</script>
