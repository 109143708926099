<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
  >
    <div>
      <v-card>
        <v-card-title>{{ type }}</v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="false"
          class="elevation-1"
        >
          <v-progress-linear
            slot="progress"
            color="blue"
            indeterminate
          />
          <template
            slot="items"
            slot-scope="props"
          >
            <td>{{ props.item.transactionID }}</td>
            <td>{{ props.item.amount }}</td>
            <td>{{ props.item.effectDate }}</td>
            <td>{{ props.item.expireDate }}</td>
            <td>{{ props.item.createDate }}</td>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'StudentFeeTransactionTable',
  props: ['items'],
  data() {
    return {
      dialog: false,
      type: '',
      headers: [
        { text: 'ID' },
        { text: 'Amount' },
        { text: 'Effective Date' },
        { text: 'Expire Date' },
        { text: 'Time Created' },
      ],
    }
  },
  methods: {
    show(type) {
      this.type = type
      this.dialog = true
    },
  },
}
</script>

<style>

</style>
