<template>
  <div>
    <v-data-iterator
      :items="studentFees"
      :rows-per-page-items="rowsPerPageItems"
      :pagination.sync="pagination"
      content-tag="v-layout"
      row
      wrap
      expand
      hide-actions
    >
      <v-flex
        slot="item"
        slot-scope="props"
        xs12
        sm6
        md4
        lg3
        @click="select(props.item)"
      >
        <v-card :dark="selectedItem === props.item">
          <v-card-title>
            <h4>{{ props.item.type }}</h4>
          </v-card-title>
          <v-divider />
          <v-list
            v-if="props.item.deductMethod === 'Schedule'"
            dense
          >
            <v-list-tile>
              <v-list-tile-content>Period:</v-list-tile-content>
              <v-list-tile-content class="align-end">
                {{ printPeriod(todayPeriod) }}
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content>Paid:</v-list-tile-content>
              <v-list-tile-content class="align-end">
                <div>{{ isCleared(props.item, todayPeriod) ? props.item.amount : 0 }}</div>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content class="align-start">
                <v-btn
                  v-if="!props.item.isClear && selectedItem === props.item"
                  color="primary"
                  small
                  @click="$emit('showHistoryDialog', props.item)"
                >
                  More
                </v-btn>
              </v-list-tile-content>
              <v-list-tile-content class="align-end">
                <div
                  v-if="selectedItem === props.item"
                  class="text-xs-center"
                >
                  <v-menu
                    offset-y
                    bottom
                    left
                  >
                    <v-btn
                      slot="activator"
                      color="primary"
                      dark
                      small
                    >
                      Clear Fee
                    </v-btn>
                    <v-list>
                      <v-list-tile
                        v-for="(period, index) in payPeriods"
                        :key="index"
                        :disabled="isCleared(props.item, period)"
                        @click="clearFee(props.item, period)"
                      >
                        <v-list-tile-title>
                          {{ `${printPeriod(period)} ${isCleared(props.item, period)?'(Cleared)':''}` }}
                        </v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </div>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <v-list
            v-else-if="props.item.deductMethod === 'Course'"
            dense
          >
            <v-list-tile>
              <v-list-tile-content>Balance:</v-list-tile-content>
              <v-list-tile-content class="align-end">
                {{ props.item.balance }}
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
            <!-- <v-list-tile-content>Status:</v-list-tile-content>
            <v-list-tile-content class="align-end">
              <div>{{ isCleared(props.item, todayPeriod) ? 'Cleared' : 'Not Cleared' }}</div>
            </v-list-tile-content> -->
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content class="align-start">
                <v-btn
                  v-if="!props.item.isClear && selectedItem === props.item"
                  color="primary"
                  small
                  @click="$emit('showHistoryDialog', props.item)"
                >
                  More
                </v-btn>
              </v-list-tile-content>
              <v-list-tile-content class="align-end">
                <v-btn
                  v-if="!props.item.isClear && selectedItem === props.item"
                  color="primary"
                  small
                  @click="$emit('showTransacDialog', props.item, 'D')"
                >
                  Deposit
                </v-btn>
              </v-list-tile-content>
            </v-list-tile>
          <!-- <v-list-tile>
            <v-list-tile-content class="align-start">
              <v-btn
                v-if="selectedItem === props.item"
                color="error"
                @click="$emit('showTransacDialog', props.item, 'W')" small>Withdraw</v-btn>
            </v-list-tile-content>
            <v-list-tile-content class="align-end">
              <v-btn
                v-if="selectedItem === props.item"
                color="primary"
                @click="$emit('showTransacDialog', props.item, 'D')" small>Deposite</v-btn>
            </v-list-tile-content>
          </v-list-tile> -->
          </v-list>
        </v-card>
      </v-flex>
    </v-data-iterator>
  </div>
</template>

<script>

export default {
  name: 'StudentFeesList',
  components: {
  },
  props: ['item', 'transactions', 'value'],
  data() {
    return {
      rowsPerPageItems: [4, 8, 12],
      pagination: {
        rowsPerPage: 99,
      },
      selectedItem: null,
    }
  },
  computed: {
    fees() {
      return this.$store.getters['fees/all']
    },
    studFeeIDs() {
      return this.$store.getters['studentFees/getByTypeID'](this.item.studentType)
    },
    studentFees() {
      if (!this.item || !this.fees || !this.studFeeIDs) { return [] }

      const today = new Date()
      return this.fees
        .filter(({ feeID }) => this.studFeeIDs.includes(feeID))
        .map((item) => {
          const fee = item
          fee.balance = this.transactions.length < 1 ? 0 :
            this.transactions
              .filter(({ feeID, effectDate, expireDate }) => feeID === fee.feeID && new Date(`${effectDate}T00:00:00`).getTime() < today.getTime() && new Date(`${expireDate}T00:00:00`).getTime() > today.getTime())
              .map(mapItem => parseFloat(mapItem.amount) || 0)
              .reduce((accum, current) => accum + current, 0)
          return fee
        })
    },
    payPeriods() {
      const { enrolStart } = this.item
      const enrolDate = new Date(enrolStart)
      const curYear = new Date().getFullYear()
      const year = enrolDate.getFullYear()
      const month = enrolDate.getMonth() + 1
      const day = enrolDate.getDate() + 1
      const periods = []

      for (let i = 0; i < (curYear - year) + 3; i += 1) {
        periods.push({
          from: `${year + i}-${this.prependZero(month)}-${this.prependZero(day)}`,
          to: `${year + i + 1}-${this.prependZero(month)}-${this.prependZero(day)}`,
        })
      }
      return periods
    },
    todayPeriod() {
      const today = new Date()
      return this.payPeriods.find(item => new Date(item.from) < today && new Date(item.to) > today)
    },
  },
  created() {
    this.selectedItem = this.$store.getters['fees/getFeeByID'](this.value)
  },
  methods: {
    select(item) {
      this.selectedItem = item
      this.$emit('input', item.feeID)
    },
    /* scheduleToPeriod(scheduleDate) {
      const [year, month, day] = scheduleDate.split('-')
      const currentDate = new Date()
      let currentYear = currentDate.getFullYear()
      let nextYear = currentYear + 1
      if(month === '00') {
        return {
          from: `${currentYear}-${currentDate.getMonth() + 1}-${day}`,
          to: `${currentYear}-${currentDate.getMonth() + 2}-${day}`
        }
      }
      if(year === '0000') {
        return {
          from: `${currentYear}-${currentDate.getMonth() + 1}-${day}`,
          to: `${nextYear}-${currentDate.getMonth() + 1}-${day}`
        }
      }

      return ''
    }, */
    printPeriod(period) {
      const { from, to } = period
      return `${from}~${to}`
    },
    /* eslint-disable no-nested-ternary */
    isCleared(fee, period) {
      const { from, to } = period

      return fee.deductMethod === 'Schedule' ?
        this.transactions.some(item => item.feeID === fee.feeID &&
          (item.effectDate === from && item.expireDate === to))
        : fee.deductMethod === 'Course' ?
          this.transactions.some(item => item.feeID === fee.feeID &&
          (new Date(item.effectDate) < new Date() && new Date(item.expireDate) > new Date()))
          : false
    },
    /* eslint-disable no-nested-ternary */
    prependZero(num) {
      return `0${num.toString().slice(-2)}`
    },
    clearFee(fee, period) {
      const { from: effectDate, to: expireDate } = period
      this.$store.dispatch('transactions/create', {
        studID: this.item.id,
        feeID: fee.feeID,
        action: 'D',
        amount: fee.amount,
        effectDate,
        expireDate,
      })
        .then((data) => {
          this.$emit('addNewTransaction', data)
        })
    },
  },
}
</script>
