<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex sm12>
        <v-card>
          <v-card-title>
            {{ `${student.firstName} ${student.lastName}'s Applicable Fees` }}
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex sm12>
        <student-fees-list
          v-model="selectedFeeID"
          :item="student"
          :transactions="transactions"
          @showTransacDialog="showTransacDialog"
          @showHistoryDialog="showHistoryDialog"
          @addNewTransaction="addNewTransaction"
        />
      </v-flex>
      <transaction-table
        ref="historyDialog"
        :items="feeTransactions"
      />
      <fee-deposite-dialog
        ref="depositeDialog"
        :fee="selectedFee"
        @addNewTransaction="addNewTransaction"
      />
    </v-layout>
  </v-container>
</template>

<script>
import StudentFeesList from '@/components/student-account/StudentFeesList.vue'
import StudentFeeTransactionTable from '@/components/student-account/StudentFeeTransactionTable.vue'
import FeeDepositeDialog from '@/components/student-account/StudentFeeDepositeDialog.vue'

export default {
  name: 'StudentAccount',
  components: {
    'student-fees-list': StudentFeesList,
    'transaction-table': StudentFeeTransactionTable,
    'fee-deposite-dialog': FeeDepositeDialog,
  },

  data() {
    return {
      student: {},
      selectedFeeID: null,
      transactions: [],
    }
  },

  computed: {
    feeTransactions() {
      return this.transactions.filter(item => item.feeID === this.selectedFeeID)
    },
    selectedFee() {
      return this.$store.getters['fees/getFeeByID'](this.selectedFeeID) || {}
    },
  },

  created() {
    this.$store.dispatch('students/getByStudentID', this.$route.params.studID)
      .then((data) => { this.student = data })

    this.$store.dispatch('transactions/getTransactionsByStudentId', this.$route.params.studID)
      .then((data) => { this.transactions = data })

    this.selectedFeeID = this.$route.params.feeID
  },

  methods: {
    select(feeID) {
      this.selectedFeeID = feeID
    },
    addNewTransaction(item) {
      this.transactions.push(item)
    },
    showTransacDialog(fee, action) {
      this.$refs.depositeDialog.show(this.student.id, fee.feeID, fee.type, action)
    },
    showHistoryDialog(fee) {
      this.$refs.historyDialog.show(fee.type)
    },
  },
}
</script>
